body.footer-body {
  font-family: 'source-sans-pro', sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.15;
  color: #3e3e3e;
}

@media (min-width: 70em) {
  body.footer-body {
    font-size: 1.05em;
  }
}

body.footer-body {
  margin: 0;
}

body.footer-body {
  font-family: 'source-sans-pro', sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  color: #3e3e3e;
}

@media (min-width: 70em) {
  body.footer-body {
    font-size: 1.05em;
  }
}

.row {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
  align-items: stretch;
}

.pad {
  padding: 1.5rem;
}

@media (min-width: 700px) {
  .pad {
    padding: 2rem;
  }
}

.col-xs-12 {
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  /* max-width: 100%; */
}

@media only screen and (min-width: 550px) {
  .col-sm-6 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
}

h3.footer-h3 {
  margin: 0 0 1.5rem 0;
}

h3.footer-h3 {
  font-weight: 600;
  font-size: 1.2em;
  line-height: 1.3;
}

@media (min-width: 700px) {
  .footer__right {
    text-align: right;
  }
}

.footer__left {
  margin-bottom: 1em;
}

@media (min-width: 700px) {
  .footer__left {
    padding-right: 1em;
    margin-bottom: 0;
  }
}

p.footer-p {
  margin: 0 0 1.5rem 0;
}

.margin-bottom05 {
  margin-bottom: 0.5rem;
}

.social_buttons {
  display: inline-flex;
  flex-wrap: wrap;
}

.margin-bottom1 {
  margin-bottom: 1rem;
}

.footer__right__avatars {
  margin-bottom: 1em;
}

/* img {
    border-style: none;
    width: 100%;
    display: block;
    height: auto;
} */

.footer__left__logo {
  max-width: 7.5em;
  flex-shrink: 0;
  margin-right: 0.5em;
}

.footer__left__logo {
  max-width: 10.5em;
}

a {
  background-color: transparent;
}

.footer__left__link {
  display: inline-block;
  padding: 0 0.5em 0.5em 0;
}

a:hover,
a:active {
  color: #3e3e3e;
}

a,
a:visited {
  color: #2f2f2f;
  transition: 0.3s;
}

a:hover,
a:active,
a:visited:hover,
a:visited:active {
  color: #3e3e3e;
}

strong {
  font-weight: bolder;
}

strong {
  font-weight: 600;
}

a.social_buttons__item {
  display: inline-flex;
  align-items: center;
  padding: 0.5em 0.78em;
  background-color: #ffffff;
  border-radius: 0.3em;
  text-decoration: none;
  color: #3e3e3e;
  margin: 0 4px 4px 0;
  line-height: 1.3;
}

.social_buttons--greybg a.social_buttons__item {
  background-color: #f7f7f7;
}

a.social_buttons__item:hover,
a.social_buttons__item:active {
  background-color: #e6e6e6;
}

.avatar {
  width: 3em;
  height: 3em;
  display: inline-flex;
  background-position: center;
  background-size: cover;
  background-color: rgba(47, 47, 47, 0.5);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  flex-shrink: 0;
  border: 1px solid #e6e6e6;
}

.avatar--withimage {
  background-color: #ffffff;
}

.footer__right__avatars__item {
  margin-right: 0.25em;
}

@media (min-width: 700px) {
  .footer__right__avatars__item {
    margin-right: 0;
    margin-left: 0.25em;
  }
}

a.avatar {
  text-decoration: none;
}

a.avatar:hover,
a.avatar:active {
  border-color: #2f2f2f;
}

.social_buttons__item__icon {
  width: 1.3em;
  height: 1.3em;
  fill: #f7f7f7;
  color: #fff;
  stroke: #3e3e3e;
  stroke-width: 5;
  transition: 0.3s;
}

.element-invisible {
  position: absolute !important;
  margin-left: -10000px !important;
  overflow: hidden;
  height: 0;
  width: 0;
}

.social_buttons__item__text {
  margin-left: 0.5em;
}

.drawer__content p {
  margin-bottom: 1em;
}

.avatar__letter {
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1.6em;
}

ul.footer-ul {
  margin: 0 0 1.5em 1.25em;
  padding: 0;
}

button.footer-bt {
  border: none;
  background: none;
  transition: all 0.3s ease;
  font-weight: 600;
  padding: 0;
  line-height: 1em;
  display: inline-flex;
  align-items: center;
  font-family: 'source-sans-pro', sans-serif !important;
  font-size: 1em !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
}

button.footer-bt {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button.footer-bt {
  overflow: visible;
}

button.footer-bt {
  text-transform: none;
}

button.footer-bt {
  -webkit-appearance: button;
}

button.footer-bt {
  color: #3e3e3e;
  cursor: unset;
}

button.drawer__button {
  position: relative;
  display: flex;
  text-align: left;
  width: 100%;
  padding: 0.8em 0.8em 0.8em 0;
}

.formio-component-htmlelement a {
  color: blue !important;
}
