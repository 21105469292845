@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}
p {
  margin-bottom: 0px !important;
}
html {
  background: #f5f7fb !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  overflow: auto;
  padding-right: unset !important;
  position: unset !important;
}
body {
  background-color: #f5f7fb !important;
}
html,
body,
#root {
  height: auto;
  font-family: 'Source Sans Pro', sans-serif;
}

#main {
  height: calc(100% - 78px);
  font-family: 'Source Sans Pro', sans-serif;
}

input[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
  display: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.webkit_in {
  display: -webkit-inline-box;
}

.webkit_out {
  display: table-caption;
}

/* Set the width and height of the scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #dfe1e1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #46bcb1;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #46bcb1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.react-date-picker__wrapper,
.react-time-picker__wrapper {
  border: 0 !important;
  padding: 0.6rem 0.8rem;
}

.react-time-picker__inputGroup__amPm {
  background: transparent;
}

.global-search-main {
  width: 100%;
}

.global-search-main .global-search__control {
  border: none !important;
  box-shadow: none;
}

.global-search-main .global-search__menu {
  border-radius: 0.5rem;
}

.global-search-main .global-search__control .global-search__value-container {
  border: 1px solid #dfe1e1 !important;
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-radius: 0.5rem;
  padding: 0.3rem !important;
  padding-left: 1rem !important;
  cursor: text;
}

.global-search-main .global-search__control .global-search__value-container .global-search__placeholder {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.global-search-main .global-search__control .global-search__value-container:active,
.global-search-main .global-search__control .global-search__value-container:focus-within {
  border-color: #1ca7d1 !important;
}

.global-search-main .global-search__control .global-search__indicators .global-search__indicator-separator {
  margin: 0;
}

.global-search-main .global-search__control .global-search__indicators .global-search__indicator {
  padding: 0 !important;
}

.global-search__menu-list {
  margin-right: 10px;
}

.global-search__menu-list > div:last-child > .custom-divider {
  display: none;
}

.custom-text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #99abb0;
}

/* On mouse-over, add a grey background color */
.radio-label:hover input[type='radio'] ~ .checkmark {
  background-color: #e3f7fc;
}

/* When the radio button is checked, add a blue background */
.radio-label input[type='radio']:checked ~ .checkmark {
  background-color: #fff;
  border-color: #1ca7d1;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-label input[type='radio']:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-label .checkmark:after {
  top: 4px;
  left: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #1ca7d1;
}

.slick-slider .slick-list .slick-track {
  margin-left: unset !important;
}

input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  /* background: #e3f7fc; */
  cursor: pointer;
  color: #1ca7d1;
  height: 0.4rem;
}

/* slider thumb */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: 0px;

  /*custom styles*/
  background-color: #1ca7d1;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 50%;
}

@layer base {
  :root {
    --primary: #1ca7d1 !important;
    --secondary: #46bcb1 !important;
    --darkPrimary: #177e9c !important;
    --lightPrimary: #e3f7fc !important;
    --primaryRed: #f95f5c !important;
    --textColor1: #333f42 !important;
    --textColor2: #707d80 !important;
    --textColor3: #99abb0 !important;
    --textColor4: #dfe1e1 !important;
    --textColor5: #f1f1f1 !important;
    --white: #ffffff !important;
    --white1: #f5f7fb !important;
    --white2: #f7fefe !important;
    --sidebarBorder: #313131 !important;
    --customGray: #646363 !important;
    --black: #000000 !important;
  }
}
.text-elc-fix .ql-editor {
  @apply !line-clamp-2;
  line-height: 1.3;
}
.custom-anchor a {
  color: white !important;
}
.custom-anchor-underline a {
  text-decoration: underline;
}
