.col-xs-12 {
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

@media only screen and (min-width: 550px) {
  .col-sm-8 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-sm-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }
}

@media only screen and (min-width: 1279px) {
  .col-lg-9 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }
}

.margin-bottom {
  margin-bottom: 1.5rem;
}

@media (min-width: 700px) {
  .margin-bottom {
    margin-bottom: 2rem;
  }
}

.message_box {
  background: #ffffff;
  border-left: 4px solid #2f2f2f;
}

.message_box--greybg {
  background-color: #f7f7f7;
}

hr.footer-hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

hr.footer-hr {
  border: 0;
  height: 1px;
  background-color: #e6e6e6;
  margin: 0 0 1.5rem 0;
}

h2.footer-h2 {
  margin: 0 0 1.5rem 0;
}

h2.footer-h2 {
  font-weight: 400;
  font-size: 1.7em;
  line-height: 1.1;
}

@media (min-width: 700px) {
  h2.footer-h2 {
    font-size: 1.85em;
  }
}

.page-content ul:not(.no-bullets) {
  list-style: none;
  counter-reset: my-awesome-counter;
  margin-left: 0;
  padding-left: 1.25rem;
}

.message_box p {
  margin-bottom: 0.5rem;
}

.message_box p:last-of-type {
  margin-bottom: 0;
}

li {
  margin-bottom: 0.5em;
}

.page-content ul:not(.no-bullets) li {
  position: relative;
  counter-increment: my-awesome-counter;
}

.page-content ol li::before,
.page-content ul:not(.no-bullets) li::before {
  color: #2f2f2f;
  font-weight: 600;
  position: absolute;
  left: -1.25rem;
}

.page-content ul:not(.no-bullets) li::before {
  content: '•';
  font-size: 1.2em;
  top: -0.2rem;
}

strong {
  font-weight: bolder;
}

strong {
  font-weight: 600;
}

a {
  background-color: transparent;
}

a {
  color: #2f2f2f;
  transition: 0.3s;
}

a:hover,
a:active {
  color: #3e3e3e;
}
